import React from "react";
import PropTypes from "prop-types";
import styles from "./style.scss";
import gql from "graphql-tag";
import { compose, graphql } from "react-apollo";
import Loading from "sections/Loading";

const GET_FOLLOWERS = gql`
  query GetFollowers($handle: String!) {
    follower(handle: $handle) {
      handle
    }
  }
`;

class Followers extends React.Component {
  render() {
    const { match } = this.props;
    const { hashid } = match.params;

    if (this.props.data.loading) {
      return <Loading />;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>Following</div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.title}>Sparkit Followers</div>
            <div className={styles.items}>
              {/* {this.props.data.follower.map((user, index) => ( */}
              {/* <div key={index} className={styles.userCardWrapper}> */}
              {/* <div className={styles.avatarAndHandleWrap}> */}
              {/* <img src="*" className={styles.userAvatar} /> */}
              {/* <div className={styles.userHandle}>{user.handle}</div> */}
              {/* </div> */}
              {/* {user ? }
                  {user.isFollowed ? (
                    <button className={styles.unfollowBtn}>Unfollow</button>
                  ) : (
                    <button className={styles.followBtn}>Follow</button>
                  )} */}
              {/* </div> */}
              {/* ))} */}
              {/* {SOCIAL_MEDIA_MOCKS.map((sm, index) => (
                <div key={index} className={styles.userCardWrapper}>
                  <div className={styles.container}>
                    <img src="*" className={styles.userAvatar} />
                    <div className={styles.userHandle}>
                      {sm.subscribers_count}
                    </div>

                    <button className={styles.visitBtn}>Visit</button>
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Followers.propTypes = {
  match: PropTypes.object.isRequired,
  data: PropTypes.shape({
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object,
  }),
};

Followers.defaultProps = {};

export default compose(
  graphql(GET_FOLLOWERS, {
    options: (props) => ({
      variables: {
        handle: props.match.params.hashid,
      },
    }),
  })
)(Followers);
