import classNames from "classnames";
import { compose, graphql } from "react-apollo";
import { connect } from "react-redux";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";

import Avatar from "components/Avatar";
import Button from "components/Button";
import FormError from "components/FormError";
import Loading from "components/Loading";

import { SPARK_QUERY } from "sections/Spark";

import handleGqlError from "data/graphqlErrors";

import * as authActions from "actions/auth";
import store from "~/store";

import styles from "./style.scss";

export class DumbCommentForm extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool, // Auth reducer
    className: PropTypes.string,
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      me: PropTypes.object,
      pageMetadata: PropTypes.object,
    }),
    depth: PropTypes.number.isRequired,
    onCancel: PropTypes.func,
    onPostConfirm: PropTypes.func,
    onPostError: PropTypes.func,
    onPostProvisional: PropTypes.func,
    parentComment: PropTypes.object.isRequired,
    postComment: PropTypes.func.isRequired,
    rootRef: PropTypes.func,
    style: PropTypes.object,
    isReplay: PropTypes.bool,
  };

  static defaultProps = {
    depth: 1,
  };

  constructor(props, context) {
    super(props, context);

    this.$root = null;

    this.state = {
      errors: null,
      message: "",
      posting: false,
      commentTestString: "",
    };
  }

  handleMessageChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.props.authenticated) {
      // User isn't authenticated; pop up the authentication modal
      store.dispatch(authActions.authRequest());
      return;
    }

    this.setState({
      posting: true,
    });

    this.props
      .postComment({
        variables: {
          message: this.state.message,
          parentHashid: this.props.parentComment.hashid,
        },
      })
      .then(() => {
        this.setState({
          errors: null,
          message: "",
          posting: false,
        });
        if (this.props.onPostConfirm) {
          this.props.onPostConfirm();
        }
      })
      .catch((error) => {
        const e = handleGqlError(error, ["message"]);

        this.setState({
          errors: e,
          posting: false,
        });
        if (this.props.onPostError) {
          this.props.onPostError();
        }
      });

    if (this.props.onPostProvisional) {
      this.props.onPostProvisional();
    }
  };

  handleCancelClick = () => {
    this.setState({
      errors: null,
      message: "",
      posting: false,
    });
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  };

  handleFocus = (event) => {
    if (!this.props.authenticated) {
      event.target.blur();
      store.dispatch(authActions.authRequest());
    }
  };

  render() {
    const { className, data, depth, rootRef, style, isReplay } = this.props;

    if (data.loading) {
      return <Loading />;
    }

    return (
      <div
        className={classNames(styles.root, className, {
          [styles.wrapMarginTop]: depth <= 1,
        })}
        style={style}
        ref={rootRef}
        data-tut="reactour__step4"
      >
        <form onSubmit={this.handleSubmit} className={classNames(styles.form)}>
          {this.state.errors && !this.state.errors.fields && (
            <FormError
              errors={(this.state.errors.general || []).concat(
                ...Object.values(this.state.errors.unhandledFields || {})
              )}
            />
          )}
          {this.state.errors &&
            this.state.errors.fields &&
            this.state.errors.fields.message && (
              <FormError errors={this.state.errors.fields.message} />
            )}
          {depth <= 1 && (
            <div className={classNames(styles.helperText)}>
              Provide your own idea(s) here
            </div>
          )}
          {depth <= 1 ? (
            <div>
              <textarea
                value={this.state.message}
                placeholder="Suggest an idea"
                onChange={this.handleMessageChange}
                onFocus={this.handleFocus}
                required
                maxLength={200}
                disabled={this.state.posting}
                className={classNames(styles.textarea, styles.commentTextarea)}
              />
            </div>
          ) : (
            <div className={styles.textAreaWrap}>
              <div className={styles.avatarWrap}>
                <Avatar size="sm" />
              </div>
              {/* <CommentInput setValue={this.handleMessageChange} /> */}
              <textarea
                value={this.state.message}
                placeholder="Add a comment"
                onChange={this.handleMessageChange}
                onFocus={this.handleFocus}
                required
                maxLength={200}
                disabled={this.state.posting}
                className={classNames(styles.commentTextarea)}
              />
            </div>
          )}

          {/* <textarea
                value={this.state.message}
                placeholder={depth <= 1 ? "Suggest an idea" : "Add a comment"}
                onChange={this.handleMessageChange}
                onFocus={this.handleFocus}
                required
                maxLength={200}
                disabled={this.state.posting}
                className={classNames(styles.textarea)}
                /> */}

          {/* {depth <= 1  && */}
          <Button
            color="primary"
            type="submit"
            disabled={this.state.posting}
            className={classNames(styles.btn, {
              [styles.addNewCommentBtn]: depth === 1 && !isReplay === true,
            })}
          >
            Post
          </Button>
          {/* } */}

          {/* {onCancel && (
            <Button
              color="secondary"
              onClick={this.handleCancelClick}
              disabled={this.state.posting}
            >
              Cancel
            </Button>
          )} */}
        </form>
      </div>
    );
  }
}

const ME_QUERY = gql`
  query CurrentUserForCommentForm {
    me {
      avatar {
        ... on Media {
          hashid
          is_processed
          square_image_sources {
            url
            width
          }
          type
        }
        ... on Url {
          url
        }
      }
      handle
      hashid
    }
  }
`;

const POST_COMMENT_MUTATION = gql`
  mutation PostComment($message: String!, $parentHashid: ID!) {
    postComment(message: $message, parent_hashid: $parentHashid) {
      children {
        hashid
      }
      created_at
      hashid
      is_voted_on_by_me
      media {
        hashid
        is_processed
        square_image_sources {
          url
          width
        }
        type
        video_sources {
          type
          url
        }
      }
      message
      permalink
      spark {
        hashid
        user {
          hashid
        }
      }
      user {
        avatar {
          ... on Media {
            hashid
            is_processed
            square_image_sources {
              url
              width
            }
            type
          }
          ... on Url {
            url
          }
        }
        handle
        hashid
      }
      votes_count
    }
  }
`;

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
  };
};

const CommentFormWithData = compose(
  connect(mapStateToProps),
  graphql(ME_QUERY),
  graphql(POST_COMMENT_MUTATION, {
    props: ({ ownProps, mutate }) => ({
      postComment: function ({ variables }) {
        return mutate({
          optimisticResponse: {
            postComment: {
              __typename: "Comment",
              children: [],
              created_at: new Date().getTime() / 1000,
              hashid: "", // No hashid is assigned yet; use this fact to highlight the comment
              is_voted_on_by_me: false,
              media: null,
              message: variables.message,
              permalink: "",
              spark: {
                __typename: "Spark",
                hashid: ownProps.parentComment.spark.hashid,
                user: {
                  __typename: "User",
                  hashid: ownProps.parentComment.spark.user.hashid,
                },
              },
              user: ownProps.data.me,
              votes_count: 0,
            },
          },
          update: (gqlStore, { data: { postComment } }) => {
            // Get full spark query results from cache
            const data = gqlStore.readQuery({
              query: SPARK_QUERY,
              variables: {
                hashid: ownProps.parentComment.spark.hashid,
              },
            });

            // Recursively loop through children to find the parent of the new
            // comment
            const checkForParent = (comment) => {
              // If this is the parent comment,
              // add the new comment to its list of children
              if (comment.hashid === ownProps.parentComment.hashid) {
                comment.children.push(postComment);
                return;
              }

              // Loop through deeper children
              if (comment.children && comment.children !== null) {
                for (const childComment of comment.children) {
                  checkForParent(childComment);
                }
              }
            };
            checkForParent(data.spark.opening_comment);

            // Write the data back to the cache
            gqlStore.writeQuery({
              data: data,
              query: SPARK_QUERY,
              variables: {
                hashid: ownProps.parentComment.spark.hashid,
              },
            });
          },
          variables: variables,
        });
      },
    }),
  })
)(DumbCommentForm);

export default CommentFormWithData;
