import classNames from "classnames";
import moment from "moment";
import momentPropTypes from "react-moment-proptypes";
import PropTypes from "prop-types";
import React from "react";

import Icon from "components/Icon";

import styles from "./style.scss";

export default class Timer extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    isProfileTimer: PropTypes.bool,
    expiresAt: PropTypes.oneOfType([
      momentPropTypes.momentObj,
      PropTypes.number,
    ]).isRequired,
    plain: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    plain: false,
    isProfileTimer: false,
  };

  constructor(props) {
    super(props);
    this.parseExpiryMoment(props);
    this.state = this.getState(props);
  }

  parseExpiryMoment(props) {
    this.expiryMoment =
      typeof props.expiresAt === "number"
        ? moment.unix(props.expiresAt)
        : props.expiresAt;
  }

  componentDidMount() {
    this.start();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.parseExpiryMoment(nextProps);
    this.setState(this.getState(nextProps));
  }

  componentDidUpdate() {
    this.start();
  }

  componentWillUnmount() {
    this.stop();
  }

  tick = () => {
    this.setState(this.getState(this.props));
  };

  stop = () => {
    clearInterval(this.interval);
  };

  start() {
    clearInterval(this.interval);
    if (this.isFuture()) {
      this.interval = setInterval(this.tick, 1e3);
    } else {
      this.interval = null;
    }
  }

  isFuture = () => {
    return this.expiryMoment.isAfter(moment());
  };

  isPast = () => {
    return !this.isFuture();
  };

  getState = (props) => {
    let message1 = null;
    let message2 = null;
    let now = moment();
    let unit = null;
    let value = null;

    if (this.isPast()) {
      message1 = "Spark";
      message2 = "ended";
      this.stop();
    } else {
      let seconds = true;
      for (unit of ["year", "month", "day", "hour", "minute"]) {
        value = this.expiryMoment.diff(now, unit, true);

        // Never round to a minute if less
        if (unit === "minute" && value < 1) {
          break;
        }

        // If more than three quarters of one of these things,
        // round to nearest one, otherwise fall through to lesser unit
        if (value > 0.75) {
          seconds = false;
          value = Math.round(value);
          break;
        }
      }

      // Format seconds
      if (seconds) {
        value = this.expiryMoment.diff(now, "second");
        unit = "second";
      }

      // Shorten unit if plain version
      if (props.plain) {
        unit = {
          day: "day",
          hour: "hour",
          minute: "min",
          month: "month",
          second: "sec",
          year: "year",
        }[unit];
      }

      // Make plural if appropriate
      if (value !== 1) {
        unit += "s";
      }

      message1 = `${value} ${unit}`;
      if (props.plain) {
        message2 = "left";
      } else {
        message2 = value === 1 ? "remains" : "remain";
      }
    }

    return {
      active: this.isFuture(),
      message1: message1,
      message2: message2,
    };
  };

  render() {
    const { className, plain } = this.props;

    return (
      <TimerDisplay
        active={this.state.active}
        className={className}
        message={`${this.state.message1} ${this.state.message2}`}
        tooltip={`Ends ${this.expiryMoment.format("ll LTS")}`}
        plain={plain}
        isProfileTimer={this.props.isProfileTimer}
      />
    );
  }
}

class TimerDisplay extends React.Component {
  static propTypes = {
    active: PropTypes.bool.isRequired,
    className: PropTypes.string,
    message: PropTypes.string.isRequired,
    plain: PropTypes.bool.isRequired,
    tooltip: PropTypes.string.isRequired,
    isProfileTimer: PropTypes.bool,
  };

  static defaultProps = {
    plain: false,
    isProfileTimer: false,
  };

  render() {
    const { active, className, message, tooltip, plain } = this.props;

    const classes = classNames(
      styles.root,
      plain ? styles.plain : styles.full,
      active ? styles.active : styles.inactive,
      styles.bgcolor,
      className
    );

    return (
      <div
        className={classNames(classes)}
        data-tooltip-id="my-tooltip"
        data-tooltip-content={tooltip}
        data-tut="reactour__step5"
      >
        <Icon className={styles.icon} icon="clock" />
        <span className={styles.label}>{message}</span>
      </div>
    );
  }
}

export { TimerDisplay };
