import { connect } from "react-redux";
import { compose, graphql } from "react-apollo";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";

import Head from "components/Head";
import Main from "components/Main";

import SparkComponent from "components/Spark";

import { SPARKS_QUERY } from "components/SparkCards";

import Loading from "sections/Loading";
import HeaderForAuthorizedUsers from "../components/HeaderForAuthorizedUsers";

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

class Spark extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool, // Auth reducer
    data: PropTypes.shape({
      error: PropTypes.object,
      loading: PropTypes.bool.isRequired,
      refetch: PropTypes.func.isRequired,
      spark: PropTypes.object,
    }),
    delete: PropTypes.func.isRequired, // GraphQL mutation
    match: PropTypes.object.isRequired, // From router
    user: PropTypes.object, // Auth reducer
  };

  static defaultProps = {
    authenticated: false,
    user: null,
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.authenticated !== nextProps.authenticated) {
      this.props.data.refetch();
    }
  }

  render() {
    const headProps = {
      back: "/",
      minimal: true,
    };

    if (!this.props.data.spark && this.props.data.loading) {
      return <Loading headProps={headProps} />;
    }

    if (this.props.data.spark && this.props.data.spark.status === "deleted") {
      return <Redirect to="/" />;
    }

    return (
      <div>
        {!this.props.user ? (
          <Head heading="Home" />
        ) : (
          <HeaderForAuthorizedUsers
            leftContent={{ content: { type: "logo" } }}
            rightContent={{ content: { type: "avatar" } }}
          />
        )}
        <Main>
          <SparkComponent
            spark={this.props.data.spark}
            delete={this.props.delete}
          />
        </Main>
      </div>
    );
  }
}

export const SPARK_QUERY = gql`
  query SparkDetail($hashid: ID!) {
    spark(hashid: $hashid) {
      closes_at
      closing_comment {
        ...commentFields
        children {
          ...commentFields
        }
        parent {
          ...commentFields
        }
      }
      created_at
      hashid
      status
      location {
        name
        place_id
      }
      opening_comment {
        ...commentFields
        children {
          ...commentFields
          children {
            ...commentFields
            children {
              ...commentFields
            }
          }
        }
      }
      ad_message
      offer {
        ad_url
        clicks
        impressions
        video_starts
        ad_shows
        impression_pixel
        name
        status
        offer_url
        file_url
      }
      permalink
      tags
      user {
        ...userFields
      }
      my_votes_remaining
    }
  }

  fragment commentFields on Comment {
    created_at
    hashid
    is_voted_on_by_me
    media {
      hashid
      is_processed
      square_image_sources {
        url
        width
      }
      type
      video_sources {
        type
        url
      }
    }
    message
    permalink
    spark {
      hashid
      user {
        hashid
      }
    }
    user {
      ...userFields
    }
    votes_count
  }

  fragment userFields on User {
    avatar {
      ... on Media {
        hashid
        is_processed
        square_image_sources {
          url
          width
        }
        type
      }
      ... on Url {
        url
      }
    }
    handle
    hashid
  }
`;

const DELETE_SPARK_MUTATION = gql`
  mutation DeleteSpark($hashid: ID!) {
    deleteSpark(hashid: $hashid)
  }
`;

export default compose(
  withRouter,
  connect(mapStateToProps, null),
  graphql(SPARK_QUERY, {
    options: (props) => {
      return {
        errorPolicy: "all",
        variables: {
          hashid: props.match.params.hashid,
        },
      };
    },
  }),
  graphql(DELETE_SPARK_MUTATION, {
    options: (props) => {
      return {
        update: (proxy) => {
          // TODO: Clear this spark's own query -- can't figure out how

          function removeSpark(variables) {
            try {
              const result = proxy.readQuery({
                query: SPARKS_QUERY,
                variables: variables,
              });
              if (!result) {
                return;
              }
              for (const [index, edge] of result.sparks.edges.entries()) {
                if (edge.node.hashid === props.match.params.hashid) {
                  result.sparks.edges.splice(index, 1);
                  proxy.writeQuery({
                    data: result,
                    query: SPARKS_QUERY,
                    variables: variables,
                  });
                  break;
                }
              }
            } catch (error) {
              // Ignore; probably not found in cache
            }
          }

          // Remove spark from profile page sparks query
          removeSpark({
            cursor: null,
            user: props.user.handle,
          });

          // Remove spark from home page sparks query
          removeSpark({
            cursor: null,
            user: null,
          });
        },
        variables: {
          hashid: props.match.params.hashid,
        },
      };
    },
    props: ({ mutate }) => ({
      delete: mutate,
    }),
  })
)(Spark);
