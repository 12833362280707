// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-CommentForm-style__wrapMarginTop__X0j2f{margin-top:30px}.components-CommentForm-style__textarea__u13jL{background-color:#f9f9f9;border-radius:10px;resize:none;width:100%}.components-CommentForm-style__form___jsFV{position:relative}.components-CommentForm-style__btn__jP5NQ{font-family:"Roboto",sans-serif;width:100%;height:50px;border-radius:10px;-webkit-box-shadow:0 8px 24px 0 rgba(247,90,0,.25);box-shadow:0 8px 24px 0 rgba(247,90,0,.25);text-transform:capitalize;font-weight:600;font-size:14px;line-height:21px;margin-bottom:5px}.components-CommentForm-style__addNewCommentBtn__vbTCw{position:absolute;bottom:-100px}.components-CommentForm-style__helperText__ysahb{margin-bottom:5px;font-family:"Roboto",sans-serif;line-height:19px;font-weight:500;font-size:12px}.components-CommentForm-style__avatarWrap__bwTjC{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end;margin-bottom:2px}.components-CommentForm-style__commentTextarea___Hc6s{min-height:58px !important;margin-bottom:10px !important;resize:none;border-radius:16px;border:1px solid #b2b2b2;background-color:#fdfdfd;width:100%;font-family:"Roboto",sans-serif !important;font-size:inherit;padding:10px;font-size:16px}.components-CommentForm-style__orangeLink__IFnV6{color:red}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapMarginTop": `components-CommentForm-style__wrapMarginTop__X0j2f`,
	"textarea": `components-CommentForm-style__textarea__u13jL`,
	"form": `components-CommentForm-style__form___jsFV`,
	"btn": `components-CommentForm-style__btn__jP5NQ`,
	"addNewCommentBtn": `components-CommentForm-style__addNewCommentBtn__vbTCw`,
	"helperText": `components-CommentForm-style__helperText__ysahb`,
	"avatarWrap": `components-CommentForm-style__avatarWrap__bwTjC`,
	"commentTextarea": `components-CommentForm-style__commentTextarea___Hc6s`,
	"orangeLink": `components-CommentForm-style__orangeLink__IFnV6`
};
export default ___CSS_LOADER_EXPORT___;
