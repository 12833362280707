import React from "react";
import PropTypes from "prop-types";
import styles from "./style.scss";
import gql from "graphql-tag";
import { compose, graphql } from "react-apollo";
import Loading from "sections/Loading";

const GET_FOLLOWEES = gql`
  query GetFollowee($handle: String!) {
    followee(handle: $handle) {
      handle
    }
  }
`;

class Following extends React.Component {
  render() {
    const { match } = this.props;
    const { hashid } = match.params;

    if (this.props.data.loading) {
      return <Loading />;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.header}>Following</div>
        <div className={styles.contentWrapper}>
          <div className={styles.content}>
            <div className={styles.title}> Sparkit Followers</div>
            {/* <div className={styles.items}>
              {this.props.data.followee.map((user, index) => (
                <div key={index} className={styles.userCardWrapper}>
                  <div className={styles.avatarAndHandleWrap}>
                    <img src="*" className={styles.userAvatar} />
                    <div className={styles.userHandle}>{user.handle}</div>
                  </div>
                  {user.isFollowed ? (
                    <button className={styles.unfollowBtn}>Unfollow</button>
                  ) : (
                    <button className={styles.followBtn}>Follow</button>
                  )}
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

Following.propTypes = {
  data: PropTypes.shape({
    error: PropTypes.object,
    loading: PropTypes.bool.isRequired,
    user: PropTypes.object,
  }),
  match: PropTypes.object.isRequired,
};

Following.defaultProps = {};

export default compose(
  graphql(GET_FOLLOWEES, {
    options: (props) => ({
      variables: {
        handle: props.match.params.hashid,
      },
    }),
  })
)(Following);

// export default withRouter(Following);
