import classNames from "classnames";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router-dom";

import clipboardCopy from "clipboard-copy";

import Tour from "reactour";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { Element, scroller, animateScroll as scroll } from "react-scroll";

import AdModal from "components/AdModal";
import Avatar from "components/Avatar";
import Comment from "components/Comment";
import CommentForm from "components/CommentForm";
import DeleteSparkModal from "components/DeleteSparkModal";
import Handle from "components/Handle";
import Help from "components/Help";
import Location from "components/Location";
import Media from "components/Media";
import ShareMenu from "components/ShareMenu";
import { TagListFromArray } from "components/TagList";
import Timer from "components/Timer";
import VotesRemainingCounter from "components/VotesRemainingCounter";
import Icon from "components/Icon";

import getConfig from "~/getConfig";
import { sessionUrl, shareUrl } from "~/shareUrl";

import styles from "./style.scss";
import HelpIcon from "../HelpIcon";
import PageAudioPlayer from "../PageAudioPlayer";
import Button from "components/Button";
import { setPrimaryCollabData } from "actions/collabEditor";

const mapStateToProps = function (state) {
  return {
    authenticated: state.auth.authenticated,
    user: state.auth.user,
  };
};

const mapDispatchToProps = {
  onSetPrimaryCollabData: setPrimaryCollabData,
};

const firstVisitSteps = [
  {
    content: "Start by watching the Influencer's Spark request.",
    observe: '[data-tut="reactour__step1--observe"]',
    position: "top",
    selector: '[data-tut="reactour__step1"]',
  },
  {
    content:
      "Click a heart to vote on your favorite suggestions. 1 vote per sugggestion. You can't vote twice.",
    selector: '[data-tut="reactour__step2"]',
  },
  {
    content:
      "Post a comment on other fan suggestions you like. It's a great way to create conversation, connection and make friends.",
    selector: '[data-tut="reactour__step3"]',
  },
  {
    content:
      "Type your suggestion to the Influencer's Spark request. Be specific and get creative to stand out from the crowd and get noticed.",
    selector: '[data-tut="reactour__step4"]',
  },
  {
    content:
      "Make a suggestion, share over social with your friends, and vote for your favorites before the Spark ends.",
    selector: '[data-tut="reactour__step5"]',
  },
];

const voteSteps = [
  {
    content:
      "Make a suggestion, share over social with your friends, and vote for your favorites before the Spark ends.",
    selector: '[data-tut="reactour__step5"]',
  },
  {
    content:
      "Click a heart to vote on your favorite suggestions. 1 vote per sugggestion. You can't vote twice.",
    selector: '[data-tut="reactour__step2"]',
  },
  {
    content:
      "Share your Spark suggestions over social to get more likes. Most likes WIN the vote.",
    selector: '[data-tut="reactour__step4"]',
  },
];

const influencerSteps = [
  {
    content: "Review Spark stats daily to improve your rank/score.",
    observe: '[data-tut="influencer__step1--observe"]',
    selector: '[data-tut="influencer__step1"]',
  },
  {
    content: "Spark set-ups and finales can be deleted.",
    observe: '[data-tut="influencer__step1--observe"]',
    selector: '[data-tut="influencer__step2"]',
  },
  {
    content:
      "Chat directly with fans and give shout outs for ideas and creativity.",
    selector: '[data-tut="reactour__step3"]',
  },
  {
    content:
      "Share each Spark 2-3 times over all social channels to ramp up voting and comments.",
    selector: '[data-tut="influencer__step4"]',
  },
  {
    content:
      "Pick the top-voted suggestion and use it to create your Spark finale.",
    selector: '[data-tut="reactour__step2"]',
  },
];

const influencerFinaleSteps = [
  {
    content:
      "Shoot your Spark finale video incorporating the top-voted suggestion and post here.",
    selector: '[data-tut="reactour__step2"]',
  },
  {
    content:
      "Give a shout-out to the winning suggestion and props to all the fans who voted and commented. Include details on how to collect a giveaway/reward if one was offered.",
    selector: '[data-tut="reactour__step3"]',
  },
];

window.dataLayer = window.dataLayer || [];

class Spark extends React.Component {
  static propTypes = {
    authenticated: PropTypes.bool, // Auth reducer
    delete: PropTypes.func,
    spark: PropTypes.object.isRequired,
    user: PropTypes.object, // Auth reducer
  };

  static defaultProps = {
    authenticated: false,
    delete: function () {},
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      anonymousVotesCount: this.getAnonymousVotesCount(),
      closed: false,
      config: null,
      deleteModalOpen: false,
      deleted: false,
      isAdOpen: false,
      isPlaying: false,
      isTourOpen: false,
      shareOpeningCommentOptionsOpen: false,
      watchedVideo: this.getWatchedVideo(),
      isPlayerOpen: false,
      isTrackPlaying: false,
      selectedComments: [],
      selectedCommentsHashids: [],
      closingCommentHashid: "",
      trackValues: {
        id: "",
        trackName: "",
        groupName: "",
        trackImg: "",
        track: "",
      },
    };

    this.closeMoment = moment.unix(this.props.spark.closes_at);

    getConfig().then((config) => {
      this.setState({
        config: config,
      });
    });
  }

  componentDidMount() {
    // Time left counter
    const closed = this.isClosed();
    this.setState({
      closed: closed,
    });
    if (closed) {
      this.tickInterval = null;
    } else {
      this.tickInterval = setInterval(this.tick, 1e3);
    }

    let counter = window.localStorage.getItem("showHelpCounter") || 0;
    window.localStorage.setItem("showHelpCounter", ++counter);
    this.setState({
      showHelpCounter: counter,
    });

    this.nullTrackValues();
    // Anonymous votes
    window.addEventListener("storage", this.handleStorage);
  }

  componentDidUpdate() {}

  componentWillUnmount() {
    // Time left counter
    this.stopTicking();
    this.StopPlaying();
    // Anonymous votes
    window.removeEventListener("storage", this.handleStorage);
  }

  handleSelectComment = (commentId, comment) => {
    const index = this.state.selectedCommentsHashids.indexOf(commentId);
    if (index === -1) {
      this.setState({
        selectedComments: [...this.state.selectedComments, comment],
        selectedCommentsHashids: [
          ...this.state.selectedCommentsHashids,
          commentId,
        ],
      });
    } else {
      const updatedIdArray = [...this.state.selectedCommentsHashids];
      const updatedCommentsArray = [...this.state.selectedComments];
      updatedIdArray.splice(index, 1);
      updatedCommentsArray.splice(index, 1);
      this.setState({
        selectedComments: updatedCommentsArray,
        selectedCommentsHashids: updatedIdArray,
      });
    }
  };

  handleSelectClick = () => {
    this.props.onSetPrimaryCollabData({
      comments: this.state.selectedComments,
      sparkHashid: this.props.spark.hashid,
      sparkCreator: this.props.spark.user.hashid,
    });
  };

  isCommentSelected = () => {
    if (this.state.selectedCommentsHashids.length >= 1) {
      return true;
    }
    return false;
  };

  showHelp = (user, authenticated) => {
    if (!this.state.closed) {
      if (!authenticated) {
        if (this.getWatchedVideo()) {
          return (
            <Help
              message="Time to Vote, Comment and Share!"
              name="helpVoteFan"
              onClick={this.helpClick}
              visible={true}
            />
          );
        }
        return (
          <Help
            message="Want to know how to get noticed and WIN the vote?  Click for a tutorial."
            name="helpNewFan"
            onClick={this.helpClick}
            visible={true}
          />
        );
      }

      if (user && !user.is_influencer) {
        return (
          <Help
            message="Need help on how to vote on a Spark?"
            name="helpVote"
            onClick={this.helpClick}
            visible={true} // TODO: check if ever voted on a spark
          />
        );
      }
    } else if (
      this.props.spark.closing_comment &&
      (!authenticated || (user && !this.isAuthor()))
    ) {
      return (
        <Help
          message="Spark closed. Did your comment WIN?"
          name="helpSparkClosed"
          onClick={this.toClosingComment}
          visible={true} // TODO: check if ever voted on a spark
        />
      );
    }

    if (user && this.isAuthor()) {
      if (this.state.closed && !this.props.spark.closing_comment) {
        return (
          <Help
            message="Need help on posting a great Spark finale?"
            name="helpAuthorFinale"
            onClick={this.helpClick}
            visible={true} // TODO: check if ever posted a spark
          />
        );
      }
      return (
        <Help
          message="Need help on how to create your best Spark?"
          name="helpAuthor"
          onClick={this.helpClick}
          visible={true} // TODO: check if ever posted a spark
        />
      );
    }

    if (user && user.is_influencer) {
      return (
        <Help
          message="Need help on how to Spark for Brands?"
          link="https://www.notion.so/sparkit-buzz/Creator-Help-8b6007c2d7da4e2e8c66a71d2c463994"
          name="helpInfluencer"
          visible={true} // TODO: check if ever posted a spark
        />
      );
    }

    return null;
  };

  getSteps = (user) => {
    if (user && this.isAuthor()) {
      if (this.state.closed) {
        return influencerFinaleSteps;
      }
      return influencerSteps;
    }

    if (this.getWatchedVideo()) {
      return voteSteps;
    }

    return firstVisitSteps;
  };

  isPlaying = () => {
    return this.state.isPlaying;
  };

  tick = () => {
    const closed = this.isClosed();
    this.setState({
      closed: closed,
    });
    if (closed) {
      this.stopTicking();
    }
  };

  stopTicking = () => {
    if (this.tickInterval != null) {
      clearInterval(this.tickInterval);
    }
  };

  nullTrackValues = () => {
    const nullTrackValues = {
      id: "",
      trackName: "",
      groupName: "",
      trackImg: "",
      track: "",
    };
    this.setState({
      trackValues: nullTrackValues,
      isTrackPlaying: false,
    });
  };

  handlePlayTrack = (getedValues) => {
    if (this.state.isTrackPlaying) {
      this.nullTrackValues();
    }
    const newTrackValues = getedValues;
    if (this.state.trackValues.id === newTrackValues.id) {
      this.setState({
        isTrackPlaying: true,
        isPlayerOpen: true,
      });
    } else {
      this.setState({
        trackValues: newTrackValues,
        isTrackPlaying: true,
        isPlayerOpen: true,
      });
    }
  };

  handleChangePlaying = (isCommentCard = false) => {
    if (isCommentCard) {
      if (this.state.isTrackPlaying) {
        this.setState({
          isPlayerOpen: false,
        });
      } else {
        this.setState({
          isPlayerOpen: true,
        });
      }
      this.setState({
        isTrackPlaying: !this.state.isTrackPlaying,
      });
    } else {
      this.setState({
        isTrackPlaying: !this.state.isTrackPlaying,
      });
    }
  };

  StopPlaying = () => {
    this.nullTrackValues();
  };

  handleChangePlayerVisibility = () => {
    this.setState({
      isPlayerOpen: !this.state.isPlayerOpen,
      isTrackPlaying: false,
    });
  };
  handleTrackPause = () => {
    this.setState({
      isPlayerOpen: false,
      isTrackPlaying: false,
    });
  };

  isClosed = () => {
    return this.closeMoment.isBefore(moment());
  };

  handleStorage = (event) => {
    if (event.key !== this.getAnonymousVotesCountKey()) {
      return;
    }
    this.setState({
      anonymousVotesCount: this.getAnonymousVotesCount(),
    });
  };

  handleMediaOnEnded = () => {
    window.localStorage.setItem(this.getWatchedVideoKey(), true);
    this.setState({
      watchedVideo: true,
    });

    if (this.props.spark.offer) {
      let eventCategory = "Spark Ad";
      window.dataLayer.push({
        event: "ad",
        gaEventAction: "Show",
        gaEventCategory: eventCategory,
        gaEventLabel: this.props.spark.hashid,
        gaEventValue: this.props.spark.offer.name,
      });
    }
    this.setState({
      isAdOpen: true,
    });
  };

  handleAdOnRequestClose = () => {
    this.setState({
      isAdOpen: false,
    });
  };

  isAuthor = () => {
    return (
      this.props.authenticated &&
      this.props.user.hashid === this.props.spark.user.hashid
    );
  };

  getAnonymousVotesCountKey = () => {
    return `anonymousVotesCount:${this.props.spark.hashid}`;
  };

  getAnonymousVotesCount = () => {
    const votes = window.localStorage.getItem(this.getAnonymousVotesCountKey());
    if (votes) {
      return parseInt(votes);
    }
    return 0;
  };

  getVotesRemaining = () => {
    if (this.props.authenticated) {
      return this.props.spark.my_votes_remaining;
    }
    if (!this.state.config) {
      return 0;
    }
    return (
      this.state.config.app.max_votes_per_thread - this.getAnonymousVotesCount()
    );
  };

  /**
   * Handle a click to share the opening comment
   *
   * @return {void}
   */
  handleShareOpeningCommentClick = () => {
    if (navigator.share) {
      window.dataLayer.push({
        event: "share",
        gaEventAction: "navigator.share",
        gaEventCategory: "Spark Share",
        gaEventLabel: this.props.spark.hashid,
      });
      navigator.share({
        url: sessionUrl(this.props.spark.permalink),
      });
    } else {
      this.setState({
        shareOpeningCommentOptionsOpen:
          !this.state.shareOpeningCommentOptionsOpen,
      });
    }
  };

  /**
   * Handle a click to share opening comment via a particular social network
   *
   * @param {string} provider - Provider
   *
   * @return {void}
   */
  handleShareOpeningCommentMenuClick = (provider) => {
    window.dataLayer.push({
      event: "share",
      gaEventAction: provider,
      gaEventCategory: "Spark Share",
      gaEventLabel: this.props.spark.hashid,
    });
    if (provider === "_copy") {
      clipboardCopy(sessionUrl(this.props.spark.permalink));
    } else {
      const url = shareUrl(
        provider,
        {
          url: sessionUrl(this.props.spark.permalink),
        },
        this.state.config.services
      );
      const newWin = window.open(url);
      newWin.focus();
    }
    this.setState({
      shareOpeningCommentOptionsOpen: false,
    });
  };

  /**
   * Handle a click on the delete spark button
   *
   * @return {void}
   */
  handleDeleteClick = () => {
    this.setState({
      deleteModalOpen: true,
    });
  };

  /**
   * Handle confirmation that a spark should be deleted
   *
   * @return {void}
   */
  handleDeleteConfirmation = () => {
    this.props.delete().then(() => {
      this.setState({
        deleted: true,
      });
    });
  };

  /**
   * Handle a request to close the delete spark modal
   *
   * @return {void}
   */
  handleDeleteModalClose = () => {
    this.setState({
      deleteModalOpen: false,
    });
  };

  sparkOnEnded = () => {
    this.setState({ isPlaying: false });
    window.dataLayer.push({
      event: "video",
      gaEventAction: "End",
      gaEventCategory: "Spark Video",
      gaEventLabel: this.props.spark.hashid,
    });
    this.handleMediaOnEnded();
  };

  handleAdClicked = () => {
    window.localStorage.setItem(this.getAdClickedKey(), true);
    this.setState({
      adClicked: true,
    });
  };

  getAdClickedKey = () => {
    return `adClicked:${this.props.spark.hashid}`;
  };

  getAdClicked = () => {
    const adClicked = window.localStorage.getItem(this.getAdClickedKey());
    if (adClicked) {
      return true;
    }
    return false;
  };

  sparkOnPlay = () => {
    this.handleTrackPause();
    if (!this.isPlaying()) {
      window.dataLayer.push({
        event: "video",
        gaEventAction: "Play",
        gaEventCategory: "Spark Video",
        gaEventLabel: this.props.spark.hashid,
      });
      this.setState({ isPlaying: true });
    }
  };

  getWatchedVideoKey = () => {
    return `watchedVideo:${this.props.spark.hashid}`;
  };

  getWatchedVideo = () => {
    const watched = window.localStorage.getItem(this.getWatchedVideoKey());
    if (watched) {
      return true;
    }
    return false;
  };

  helpClick = () => {
    this.setIsTourOpen(true);
  };

  setIsTourOpen = (open) => {
    this.setState({ isTourOpen: open });
  };

  disableBody = (target) => disableBodyScroll(target);
  enableBody = (target) => enableBodyScroll(target);

  tourClose = () => {
    this.setIsTourOpen(false);
    if (this.getWatchedVideo()) {
      scroller.scrollTo("topOfCommentArea");
    } else {
      scroll.scrollToTop();
    }
  };

  toClosingComment = () => {
    scroller.scrollTo("topOfCommentArea", {
      offset: -60,
    });
  };

  render() {
    if (this.state.deleted) {
      return <Redirect to="/" />;
    }

    const { spark, user, authenticated } = this.props;

    return (
      <div className={styles.root}>
        {this.showHelp(user, authenticated)}

        <div className={styles.wrap}>
          <div
            className={classNames(
              styles.content,
              "wrap__inner",
              "wrap__inner--skinny"
              // "wrap__gutter",
              // "wrap__gutter--padded"
            )}
          >
            <div className={classNames(styles.topSparkPart)}>
              <AdModal
                spark={spark}
                isOpen={this.state.isAdOpen}
                onRequestClose={this.handleAdOnRequestClose}
                onClick={this.handleAdClicked}
              />

              <header className={styles.header} ref={this.myRef}>
                {/* {this.renderHasoffersImpressionPixel()} */}
                <Avatar className={styles.avatar} size="md" user={spark.user} />
                <span className={styles.information}>
                  <h1 className={classNames("title", "title-md")}>
                    <Handle handle={spark.user.handle} />
                  </h1>
                  {spark && spark.location && (
                    <Location name={spark.location.name} />
                  )}
                </span>
                <ShareMenu
                  listClassName={styles.shareOpeningCommentOptionsMenu}
                  onButtonClick={this.handleShareOpeningCommentClick}
                  onShareMenuItemClick={this.handleShareOpeningCommentMenuClick}
                  open={this.state.shareOpeningCommentOptionsOpen}
                  isShareIconWithText
                />
              </header>
              <Media
                size="lg"
                media={spark.opening_comment.media}
                onEnded={this.sparkOnEnded}
                onPlay={this.sparkOnPlay}
              />
              {spark.opening_comment.message && (
                <p className={styles.message}>
                  {spark.opening_comment.message}
                </p>
              )}
              <div
                className={classNames(
                  styles.tagsAndShareContainer,
                  "m-t-20 m-b-20"
                )}
                data-tut="influencer__step4"
              >
                {this.renderTags()}
                {/* <ShareMenu
                  listClassName={styles.shareOpeningCommentOptionsMenu}
                  onButtonClick={this.handleShareOpeningCommentClick}
                  onShareMenuItemClick={this.handleShareOpeningCommentMenuClick}
                  open={this.state.shareOpeningCommentOptionsOpen}
                /> */}
              </div>
            </div>

            <div
              className={classNames(styles.timerWrapper, {
                [styles.closedTimerWrapper]: this.state.closed,
              })}
            >
              <Timer
                expiresAt={this.props.spark.closes_at}
                // ClassName="m-t-20 m-b-20"
              />
            </div>
            <div className={classNames(styles.bottomSparkPart)}>
              <Element name="topOfCommentArea"></Element>
              {this.renderCommentsArea()}

              {this.canPostFirstLevelComment() && (
                <CommentForm parentComment={spark.opening_comment} />
              )}

              {!this.state.closed && (
                <VotesRemainingCounter
                  className={styles.votesRemainingCounter}
                  count={this.getVotesRemaining()}
                />
              )}

              {this.isAuthor() && (
                <DeleteSparkModal
                  isOpen={this.state.deleteModalOpen}
                  onConfirm={this.handleDeleteConfirmation}
                  onRequestClose={this.handleDeleteModalClose}
                />
              )}
            </div>
          </div>
        </div>
        <div>
          <Tour
            steps={this.getSteps(user, authenticated)}
            isOpen={this.state.isTourOpen}
            onRequestClose={this.tourClose}
            onAfterOpen={this.disableBody}
            onBeforeClose={this.enableBody}
            startAt={0}
          />
        </div>
      </div>
    );
  }

  renderHasoffersImpressionPixel = () => {
    if (!this.props.spark.offer) {
      return null;
    }

    return (
      <img height="1" width="1" src={this.props.spark.offer.impression_pixel} />
    );
  };

  renderTags = () => {
    if (!this.props.spark.tags || !this.props.spark.tags.length) {
      return null;
    }

    return <TagListFromArray linked={false} tags={this.props.spark.tags} />;
  };

  canPostFirstLevelComment = () => {
    return !this.state.closed;
  };

  onAnonymousVoteRetracted = () => {
    const count = this.getAnonymousVotesCount() - 1;
    window.localStorage.setItem(this.getAnonymousVotesCountKey(), count);
    this.setState({
      anonymousVotesCount: count,
    });
  };

  onAnonymousVoteUsed = () => {
    const count = this.getAnonymousVotesCount() + 1;
    window.localStorage.setItem(this.getAnonymousVotesCountKey(), count);
    this.setState({
      anonymousVotesCount: count,
    });
  };

  renderCommentsArea = () => {
    return (
      <div>
        {this.isAuthor() && (
          <div className={styles.authorActions}>
            {/* <div
              className={styles.buttonRow}
              data-tut="influencer__step1--observe"
            >
              <Button
                size="lg"
                color="tertiary"
                link="/profile/report"
                dataTut="influencer__step1"
              >
                View stats
              </Button> */}
            <div className={styles.helperIconWrapper}>
              <HelpIcon helperText="The trash can is available if you, or your team, need to delete a Spark intro or comment." />
            </div>
            <button
              onClick={this.handleDeleteClick}
              className={styles.deleteSparkBtn}
            >
              Delete sparks
              <Icon icon="remove" size="sm" />
            </button>
            {/* </div> */}
          </div>
        )}

        {this.props.spark.closing_comment && (
          <div className={styles.closing_comment}>
            {this.props.spark.closing_comment.parent ? (
              <>
                <Comment
                  comment={this.props.spark.closing_comment.parent}
                  childComments={[this.props.spark.closing_comment]}
                  childOfTheClosingComment={
                    this.props.spark.opening_comment.children[
                      this.props.spark.opening_comment.children.findIndex(
                        (comment) => {
                          if (
                            !this.props.spark.closing_comment ||
                            !this.props.spark.closing_comment.parent
                          ) {
                            return comment.hashid === "";
                          }
                          return (
                            comment.hashid ===
                            this.props.spark.closing_comment.parent.hashid
                          );
                        }
                      )
                    ].children
                  }
                  depth={0}
                  onAnonymousVoteRetracted={this.onAnonymousVoteRetracted}
                  onAnonymousVoteUsed={this.onAnonymousVoteUsed}
                  onMediaEnded={this.handleMediaOnEnded}
                  sparkAuthorHashid={this.props.spark.user.hashid}
                  sparkClosed={this.state.closed}
                  sparkClosesAt={this.props.spark.closes_at}
                  sparkClosingCommentHashid={
                    this.props.spark.closing_comment.hashid
                  }
                  sparkHasClosingComment
                  sparkHashid={this.props.spark.hashid}
                  sparkVotesRemaining={this.getVotesRemaining()}
                  highlight={
                    this.props.spark.closing_comment.parent.hashid === ""
                  }
                  handleChangePlaying={this.handleChangePlaying}
                  handlePlayTrack={this.handlePlayTrack}
                  isTrackPlaying={this.state.isTrackPlaying}
                  trackValues={this.state.trackValues}
                  selectedComments={this.state.selectedComments}
                  selectedCommentsHashids={this.state.selectedCommentsHashids}
                  handleSelectComment={this.handleSelectComment}
                />
              </>
            ) : (
              <Comment
                comment={this.props.spark.closing_comment}
                depth={0}
                isClosingComment={true}
                onAnonymousVoteRetracted={this.onAnonymousVoteRetracted}
                onAnonymousVoteUsed={this.onAnonymousVoteUsed}
                onMediaEnded={this.handleMediaOnEnded}
                sparkAuthorHashid={this.props.spark.user.hashid}
                sparkClosed={this.state.closed}
                sparkClosesAt={this.props.spark.closes_at}
                sparkClosingCommentHashid={
                  this.props.spark.closing_comment &&
                  this.props.spark.closing_comment.hashid
                }
                sparkHasClosingComment
                sparkHashid={this.props.spark.hashid}
                sparkVotesRemaining={this.getVotesRemaining()}
                highlight={this.props.spark.closing_comment.hashid === ""}
                handleChangePlaying={this.handleChangePlaying}
                handlePlayTrack={this.handlePlayTrack}
                isTrackPlaying={this.state.isTrackPlaying}
                trackValues={this.state.trackValues}
                selectedComments={this.state.selectedComments}
                selectedCommentsHashids={this.state.selectedCommentsHashids}
                handleSelectComment={this.handleSelectComment}
              />
            )}
          </div>
        )}

        <div>
          {this.props.spark.opening_comment.children.map((comment) => {
            if (
              this.props.spark.closing_comment &&
              comment.hashid === this.props.spark.closing_comment.parent.hashid
            ) {
              return <div key={comment.hashid}></div>;
            }
            return (
              <Comment
                key={comment.hashid}
                comment={comment}
                depth={1}
                onAnonymousVoteRetracted={this.onAnonymousVoteRetracted}
                onAnonymousVoteUsed={this.onAnonymousVoteUsed}
                sparkAuthorHashid={this.props.spark.user.hashid}
                sparkClosed={this.state.closed}
                sparkClosesAt={this.props.spark.closes_at}
                sparkClosingCommentHashid={
                  this.props.spark.closing_comment &&
                  this.props.spark.closing_comment.hashid
                }
                sparkHasClosingComment={!!this.props.spark.closing_comment}
                sparkHashid={this.props.spark.hashid}
                sparkVotesRemaining={this.getVotesRemaining()}
                highlight={comment.hashid === ""}
                handleChangePlaying={this.handleChangePlaying}
                handlePlayTrack={this.handlePlayTrack}
                isTrackPlaying={this.state.isTrackPlaying}
                trackValues={this.state.trackValues}
                selectedComments={this.state.selectedComments}
                selectedCommentsHashids={this.state.selectedCommentsHashids}
                handleSelectComment={this.handleSelectComment}
              />
            );
          })}
        </div>
        <div
          className={classNames({
            [styles.pageAudioPlayerNone]: !this.state.isPlayerOpen,
            [styles.pageAudioPlayerBlock]: this.state.isPlayerOpen,
          })}
        >
          <PageAudioPlayer
            isPlaying={this.state.isTrackPlaying}
            setIsPlaying={this.handleChangePlaying}
            setIsOpen={this.handleChangePlayerVisibility}
            trackName={this.state.trackValues.trackName}
            groupName={this.state.trackValues.groupName}
            trackImg={this.state.trackValues.trackImg}
            track={this.state.trackValues.track}
          />
        </div>
        {this.isCommentSelected() && (
          <Button
            className={styles.selectButton}
            onClick={this.handleSelectClick}
            link={`/spark/${this.props.spark.hashid}/decision/${this.state.selectedCommentsHashids[0]}`}
          >
            Select
          </Button>
        )}
      </div>
    );
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Spark);
